import React, {useEffect, useState} from 'react'
import FadeIn from '../components/UI/atoms/FadeIn'
import {BlopLink, Ratio} from '../components/UI/atoms'
import {motion} from 'framer-motion'
import {Box, Flex, Heading, Link, SimpleGrid, Text} from '@chakra-ui/react'
import {useHomepage} from '../hooks'
import {SanityImage} from '../gatsby-source-sanity'
import responsiveFontSize from '../@chakra-ui/gatsby-plugin/responsiveFontSize'

const WorksPage = () => {
	const {
		works_title,
		works_subtitle,
		works
	} = useHomepage()

	const [list, setList] = useState([...works.slice(0, 12)])
	const [loadMore, setLoadMore] = useState(false)
	const [hasMore, setHasMore] = useState(works.length > 12)

	useEffect(() => {
		if (loadMore && hasMore) {
			const currentLength = list.length
			const isMore = currentLength < works.length
			const nextResults = isMore
				? works.slice(currentLength, currentLength + 12)
				: []
			setList([...list, ...nextResults])
			setLoadMore(false)
		}
	}, [loadMore, hasMore]) //eslint-disable-line

	useEffect(() => {
		const isMore = list.length < works.length
		setHasMore(isMore)
	}, [list]) //eslint-disable-line

	return <React.Fragment>
		<FadeIn delayOrder={0.25}>
			<Heading fontSize={{ base: responsiveFontSize(60), md: responsiveFontSize(100)}}
							 lineHeight={{base: responsiveFontSize(80), md: responsiveFontSize(145)}} textAlign={'center'} w={'full'}
							 mt={'10vh'}>{works_title}</Heading>
		</FadeIn>
		<FadeIn delayOrder={0.5}>
			<Text fontSize={responsiveFontSize(30)} lineHeight={responsiveFontSize(44)} textAlign={'center'} w={'full'}
						mt={'2vw'}>{works_subtitle}</Text>
		</FadeIn>
		<SimpleGrid columns={[1, 1, 1, 2, 3]} gap={responsiveFontSize(50)} my={'10vh'}>
			{list && list.map((work, index) => {
				return <FadeIn delayOrder={0.2 * (index % 12)} key={work.title}>
					<Box
						position={'relative'}
						as={motion.div}
						whileHover={'active'}
						whileFocus={'active'}
					>
						<Link
							href={work.url}
							isExternal
							className={'nounderline'}
							as={motion.a} position={'absolute'}
							inset={0} opacity={{
							base: 0.5,
							lg: 0
						}}
							bg={'rgba(0,0,0,0.5)'}
							zIndex={2}
							variants={{
								'active': {
									scale: 1.15,
									opacity: 0.5
								}
							}}
						/>
						<Box
							as={motion.div}
							variants={{
								'active': {
									scale: 1.15
								}
							}}
						>
							<Ratio ratio={'16:9'} border={'1px solid'}>
								<SanityImage image={work.banner} objectFit={'cover'} h={'full'}/>
							</Ratio>
						</Box>
						<Flex
							as={motion.div}
							alignItems={'center'} justifyContent={'center'}
							position={'absolute'}
							top={'50%'}
							insetX={0}
							transform={'translateY(-50%)'}
							visibility={{
								base: 'visible',
								lg: 'hidden'
							}}
							opacity={{
								base: 1,
								lg: 0
							}}
							variants={{
								'active': {
									visibility: 'visible',
									opacity: 1
								},
								'mobile_active': {
									visibility: 'visible',
									opacity: 1
								}
							}}
							zIndex={3}
						>
							<Link
								as={motion.a}
								href={work.url}
								isExternal
								color={'white'}
								textAlign={'center'}
								fontFamily={'recoleta'}
								fontSize={responsiveFontSize(30)}
								lineHeight={responsiveFontSize(44)}
								sx={{
									'&:before': {
										bg: 'white !important'
									}
								}}
							>{work.title}</Link>
						</Flex>
					</Box>
				</FadeIn>
			})}
		</SimpleGrid>
		{hasMore && <Flex justifyContent={'center'} mt={responsiveFontSize(50)}>
			<BlopLink fontSize={responsiveFontSize(30)} lineHeight={responsiveFontSize(44)} onClick={() => setLoadMore(true)}>Load
				more</BlopLink>
		</Flex>}
		<Box mb={'20vh'}/>
	</React.Fragment>
}

export default WorksPage